import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';
import { Box, Typography, CircularProgress, Stack, IconButton } from '@mui/material';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import {
  GET_RESTRICTIONS_UNCREATED,
  GET_MASTER_ENTITY_RESTRICTIONS,
  CHANGE_COMPANY_EVALUATION_RESTRICTION_STATUS,
} from '@fingo/lib/graphql';
import DeleteIcon from '@mui/icons-material/Delete';
import { HISTORY_RESTRICTION_COLUMNS } from '../../../treasury/constants/treasury';
import RestrictionDetails from './RestrictionDetail';
import NoRestrictionsMessage from './NoRestrictionsMessage';

const RestrictionsHistory = ({ companyId }) => {
  const { loading, data, error, refetch } = useQuery(GET_MASTER_ENTITY_RESTRICTIONS, {
    variables: { masterEntityId: companyId },
  });
  const { addAlert } = useSnackBars();

  const groupedRows = useMemo(() => {
    if (loading || error) return [];

    const company = data?.getMasterEntity?.company || {};

    // Obtener las restricciones y formatear la fecha
    const restrictions = company.companyevaluationrestrictionsSet
      .filter((restriction) => restriction.status !== 'DELETED')
      .map((restriction) => ({
        ...restriction,
        company,
        createdDate: new Date(restriction.createdAt),
      }));

    // Agrupar por fecha
    const groups = restrictions.reduce((acc, restriction) => {
      const dateKey = restriction.createdDate.toDateString();

      let existingGroup = acc.find((group) => group.date === dateKey);
      if (!existingGroup) {
        existingGroup = { date: dateKey, restrictions: [] };
        acc.push(existingGroup);
      }
      existingGroup.restrictions.push(restriction);
      return acc;
    }, []);

    // Ordenar los grupos por fecha (descendente)
    return groups.sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [loading, error, data]);

  const [deleteCompanyRestriction] = useMutation(
    CHANGE_COMPANY_EVALUATION_RESTRICTION_STATUS,
    {
      refetchQueries: [
        GET_RESTRICTIONS_UNCREATED,
      ],
      onError: () => {
        addAlert({
          id: 'delete-restriction-error',
          message: 'Hubo un error, comunícate con el equipo de desarrollo.',
        });
      },
      onCompleted: () => {
        addAlert({
          id: 'delete-restriction',
          message: 'Se eliminó la restricción correctamente!',
        });
        refetch();
      },
    },
  );

  const handleDeleteCompanyRestriction = (id) => {
    deleteCompanyRestriction({
      variables: {
        restriction: id,
        action: 'delete',
      },
    });
  };

  const columns = [
    ...HISTORY_RESTRICTION_COLUMNS,
    {
      field: 'deleteAction',
      headerName: '',
      width: 50,
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        row.status === 'CREATED' ? (
          <IconButton onClick={() => handleDeleteCompanyRestriction(row.id)}>
            <DeleteIcon />
          </IconButton>
        ) : null
      ),
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h6" color="error">
          Error al tratar de obtener las restricciones
        </Typography>
      </Box>
    );
  }

  if (!groupedRows.length) {
    return (
      <Stack spacing={2}>
        <Typography variant="h5" align="center" gutterBottom>
          Historial de restricciones
        </Typography>
        <NoRestrictionsMessage message="No se encontraron restricciones" />
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5" align="center" gutterBottom>
        Historial de restricciones
      </Typography>
      {groupedRows.map(({ date, restrictions }, index) => (
        <Box key={date}>
          <Typography variant="h6" align="left" gutterBottom>
            {new Date(date).toLocaleDateString('es-ES', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}
          </Typography>
          <FingoTable
            columns={columns}
            includeHeaders={[
              'restriction',
              'status',
              'resolutionExplanation',
              'archivo',
              'deleteAction',
            ]}
            rows={restrictions}
            showHeaders={index === 0}
            loading={false}
            noRowsMessage={() => <Typography>La empresa no tiene restricciones</Typography>}
            rowCount={restrictions.length}
            customPaperBackground="transparent"
            pagination={false}
            collapsible
            isRowCollapsible={() => true}
            collapseComponent={({ row }) => <RestrictionDetails row={row} />}
          />
        </Box>
      ))}
    </Stack>
  );
};

RestrictionsHistory.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default RestrictionsHistory;
