import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CircularProgress, Grid, TextField, Button, FormControlLabel, Radio, RadioGroup, Typography, FormGroup, Checkbox, Tooltip, IconButton } from '@mui/material';
import parse from 'html-react-parser';
import { GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW, PREEVALUATION_EMAIL_SEND, GENERATE_RISK_PDF_LINK } from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import { useBooleanState } from '@fingo/lib/hooks';
import Email from '@mui/icons-material/Email';
import { FingoDialog } from '@fingo/lib/components/dialogs';

const targetMail = [
  { label: 'CEO', name: 'jmg' },
  { label: 'Jefe de Riesgo', name: 'rocio' },
  { label: 'Analista de Riesgo', name: 'denisse' },
];
const acceptLabels = [
  { label: 'Si', name: true },
  { label: 'No', name: false },
];

const RiskEvaluationEmail = ({ companyName, modelName, documentsToEvaluate, disabled }) => {
  const [openMailEvaluation, toggleOpenMailEvaluation] = useBooleanState();
  const [comments, setComments] = useState('');
  const [emailSending, setEmailSending] = useState('');
  const [acceptPreoffer, setAcceptPreoffer] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const documentsIds = documentsToEvaluate.map((document) => (document.id));
  const { data, loading: loadingPreview } = useQuery(
    GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW,
    {
      variables: {
        modelName,
        documentIds: documentsIds,
      },
      skip: !openMailEvaluation,
    },
  );
  const [sendEvaluationEmail, { loading, error,
  }] = useMutation(PREEVALUATION_EMAIL_SEND, {
    variables: {
      modelName,
      documentsIds,
      acceptPreoffer,
      finalDescription: comments,
      emailSending,
      isPrivate,
    },
    onCompleted: toggleOpenMailEvaluation,
  });

  const [sendPDFEmail, { loading: PDFLoading, error: PDFError,
  }] = useLazyQuery(GENERATE_RISK_PDF_LINK, {
    variables: {
      invoiceIds: documentsIds,
    },
    onCompleted: toggleOpenMailEvaluation,
  });
  const automaticEmailPreview = data?.getAutomaticResponseEmailPreview.preview;
  if (loadingPreview) return <CircularProgress color="gray" />;
  return (
    <>
      <Tooltip title="Mail Evaluación">
        <span>
          <IconButton
            sx={{
              maxWidth: '39px',
              maxHeight: '39px',
              borderRadius: 2,
              color: 'white',
              backgroundColor: 'red',
              '&.Mui-disabled': {
                color: null,
                backgroundColor: 'grey',
              },
              '&:hover': {
                backgroundColor: '#d32f2f',
                color: 'white',
              },
            }}
            onClick={toggleOpenMailEvaluation}
            disabled={disabled}
          >
            <Email />
          </IconButton>
        </span>
      </Tooltip>
      <FingoDialog
        key="email-evaluations-dialog"
        id="email-evaluations-dialog"
        title="Envío de Email de Evaluación"
        subtitle={companyName}
        open={openMailEvaluation}
        handleClose={toggleOpenMailEvaluation}
        maxWidth="lg"
        fullWidth
      >
        {automaticEmailPreview && <div>{parse(automaticEmailPreview)}<br /></div>}
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item sx={{ width: '70%' }}>
            <TextField
              sx={{ width: '100%' }}
              variant="outlined"
              name="acceptComments"
              label="Comentarios de Evaluación"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" style={{ padding: '1.5rem' }}>
          <Grid item>
            <Typography variant="h6">¿A aquién enviar?</Typography>
            <RadioGroup
              value={emailSending}
              onChange={(e) => { setEmailSending(e.target.value); }}
            >
              {targetMail.map((doc) => (
                <FormControlLabel
                  key={doc.name}
                  value={doc.name}
                  control={<Radio />}
                  label={doc.label}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item>
            <Typography variant="h6">¿Aceptar Evaluación?</Typography>
            <RadioGroup
              value={acceptPreoffer}
              onChange={(e) => {
                setAcceptPreoffer(e.target.value === 'true');
              }}
            >
              {acceptLabels.map((doc) => (
                <FormControlLabel
                  key={doc.name}
                  value={doc.name}
                  control={<Radio />}
                  label={doc.label}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isPrivate}
                    disabled={['false', null].includes(acceptPreoffer)}
                    onClick={(e) => { setIsPrivate(e.target.checked); }}
                    name="responded"
                  />
                )}
                label="¿Mail privado?"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <LoadingButton
              loading={loading}
              onClick={() => sendEvaluationEmail()}
              variant="contained"
              color={error ? 'warning' : 'primary'}
              size="small"
              disabled={emailSending === '' || acceptPreoffer === null}
              sx={{ marginRight: 1 }}
            >
              {error ? 'Error en envio de mail' : 'Enviar Mail'}
            </LoadingButton>
            <LoadingButton
              loading={PDFLoading}
              onClick={sendPDFEmail}
              variant="contained"
              color={PDFError ? 'warning' : 'primary'}
              size="small"
              sx={{ marginRight: 1 }}
            >
              {PDFError ? 'Error al generar PDF' : 'Generar PDF'}
            </LoadingButton>
            <Button variant="contained" color="primary" size="small" sx={{ marginRight: 1 }} onClick={toggleOpenMailEvaluation}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </FingoDialog>
    </>
  );
};

RiskEvaluationEmail.propTypes = {
  disabled: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  modelName: PropTypes.oneOf(['invoice', 'purchaseOrder']).isRequired,
  documentsToEvaluate: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
};

export default RiskEvaluationEmail;
