import { Badge, IconButton } from '@mui/material';
import React, { useMemo } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useBooleanState } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { InvoiceType } from '@fingo/lib/propTypes';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AutomaticEvaluationContent from './automaticEvaluation/AutomaticEvaluationContent';

const COLORS = {
  REJECT: 'error',
  MANUAL: 'warning',
  NOAPPROVE: 'warning',
  SEMIAUTOMATIC: 'warning',
  ACCEPTWITHOUTRESTRICTIONCLIENT: 'success',
  ACCEPTWITHOUTRESTRICTIONDEBTOR: 'success',
};

const AutomaticEvaluation = ({ invoice }) => {
  const {
    riskEvaluationModel,
    preoffer: { preofferevaluationrequest: { riskautomaticevaluationlogSet } },
  } = invoice;
  const [open, toggleOpen] = useBooleanState();
  const buttonColor = useMemo(() => {
    if (!riskautomaticevaluationlogSet?.length) return 'action';
    return COLORS[riskautomaticevaluationlogSet[0].resolution];
  }, [riskautomaticevaluationlogSet]);
  const hasWarning = useMemo(() => {
    if (!riskEvaluationModel) return false;
    return (!(riskEvaluationModel.treeClientDebtorResult
      && riskEvaluationModel.treeClientResult
      && riskEvaluationModel.treeDebtorResult));
  }, [riskEvaluationModel]);
  return (
    <>
      <Badge badgeContent={hasWarning && <PriorityHighIcon color="error" />}>
        <IconButton
          variant="contained"
          size="small"
          color="primary"
          onClick={toggleOpen}
          disabled={!riskEvaluationModel}
        >
          <CircleIcon color={buttonColor} />
        </IconButton>
      </Badge>
      <FingoDialog
        open={open}
        handleClose={toggleOpen}
        title="Modelos predictivos"
        subtitle=""
        fullWidth
        maxWidth="xl"
        paddingContent={0}
        fullScreen
      >
        <AutomaticEvaluationContent invoice={invoice} />
      </FingoDialog>
    </>
  );
};

AutomaticEvaluation.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default AutomaticEvaluation;
