import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useMutation } from '@apollo/client';
import { IconButton, FormGroup, FormControlLabel, Checkbox, Button, Box } from '@mui/material';
import { RERUN_EVALUATION_REQUEST } from '@fingo/lib/graphql/invoices';
import ReplayIcon from '@mui/icons-material/Replay';
import { useBooleanState } from '@fingo/lib/hooks';

const RiskReExecute = ({ documentId, documentType }) => {
  const [open, setOpen] = useBooleanState();
  const [clientDicom, setClientDicom] = useState(false);
  const [debtorDicom, setDebtorDicom] = useState(false);
  const [clientBusinessProfile, setClientBusinessProfile] = useState(false);
  const [debtorBusinessProfile, setDebtorBusinessProfile] = useState(false);
  const [clientCurrentBehavior, setClientCurrentBehavior] = useState(false);
  const [debtorCurrentBehavior, setDebtorCurrentBehavior] = useState(false);
  const [rerunEvaluationRequest] = useMutation(
    RERUN_EVALUATION_REQUEST,
    { onCompleted: () => {
      setOpen();
    } },
  );
  const executeEvaluationRequest = () => {
    rerunEvaluationRequest({
      variables: {
        objectId: documentId,
        objectType: documentType,
        clientDicom,
        debtorDicom,
        clientBusinessProfile,
        debtorBusinessProfile,
        clientCurrentBehavior,
        debtorCurrentBehavior,
      },
    });
  };

  return (
    <>
      <IconButton
        variant="contained"
        size="small"
        color="primary"
        onClick={() => setOpen(!open)}
      >
        <ReplayIcon />
      </IconButton>
      <FingoDialog
        title="Re-ejecutar Modelo de Riesgo"
        open={open}
        handleClose={() => setOpen(!open)}
        maxWidth="sm"
        fullWidth
      >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={clientDicom} onClick={(e) => setClientDicom(e.target.checked)} />} label="Actualizar Dicom Cliente" />
          <FormControlLabel control={<Checkbox checked={debtorDicom} onClick={(e) => setDebtorDicom(e.target.checked)} />} label="Actualizar Dicom Deudor" />
          <FormControlLabel control={<Checkbox checked={clientBusinessProfile} onClick={(e) => setClientBusinessProfile(e.target.checked)} />} label="Actualizar Perfil Comercial Cliente" />
          <FormControlLabel control={<Checkbox checked={debtorBusinessProfile} onClick={(e) => setDebtorBusinessProfile(e.target.checked)} />} label="Actualizar Perfil Comercial Deudor" />
          <FormControlLabel control={<Checkbox checked={clientCurrentBehavior} onClick={(e) => setClientCurrentBehavior(e.target.checked)} />} label="Actualizar Comportamiento Vigente Cliente" />
          <FormControlLabel control={<Checkbox checked={debtorCurrentBehavior} onClick={(e) => setDebtorCurrentBehavior(e.target.checked)} />} label="Actualizar Comportamiento Vigente Deudor" />
        </FormGroup>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button color="primary" onClick={() => setOpen()}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => executeEvaluationRequest()}>
            Confirmar
          </Button>
        </Box>
      </FingoDialog>
    </>
  );
};

RiskReExecute.propTypes = {
  documentId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
};

export default RiskReExecute;
