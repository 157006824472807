/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import DicomComponent from '@fingo/lib/components/dicom/DicomComponent';
import {
  GET_RISK_VARIABLES,
  GET_RISK_VARIABLES_DESCRIPTION,
} from '@fingo/lib/graphql/invoices';
import { formatDateWordsWithYear, formatTime } from '@fingo/lib/helpers/date';
import { useBooleanState } from '@fingo/lib/hooks';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import { Stack, Tab, Tabs } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import riskEvaluationsVariablesColumns from '../../constants/risk-evaluations-variables-columns';
import RiskVariablesAlert from './RiskVariablesAlert';

const RiskVariables = ({
  riskEvaluation,
  documentId,
  documentType,
  client,
  receiver,
}) => {
  const [open, setOpen] = useBooleanState();
  const [selectedTab, setSelectedTab] = useState(0);
  const { data: retrieveRiskVariables, loading: variablesLoading } = useQuery(
    GET_RISK_VARIABLES,
    {
      variables: {
        objectId: documentId,
        modelName: documentType,
      },
      skip: !open,
      fetchPolicy: 'network-only',
    },
  );
  const riskVariables = retrieveRiskVariables?.getRiskVariables.fetchVariables || [];
  const {
    data: retrieveVariablesDescription,
    loading: variablesDescriptionLoading,
  } = useQuery(GET_RISK_VARIABLES_DESCRIPTION, {
    skip: !open,
  });
  const variablesDescriptionResult = retrieveVariablesDescription?.getRiskEvaluationVariablesDescription || [];
  const variablesDescription = useMemo(
    () => Object.assign(
      {},
      ...variablesDescriptionResult.map((x) => ({ [x.name]: x })),
    ),
    [variablesDescriptionResult],
  );
  const formatRiskEvaluationDateTime = useCallback((variables_) => {
    const datetime = moment(
      variables_?.find((e) => e.name === 'created_at')?.value,
    );
    return `${formatDateWordsWithYear(datetime)} a las ${formatTime(datetime)}`;
  }, []);

  const retrieveNamesToFilter = useCallback(
    (index) => ({
      0: ['client', 'client_debtor'],
      1: ['debtor', 'client'],
      2: ['client_debtor', 'debtor'],
    }[selectedTab][index]),
    [selectedTab],
  );

  const descriptionNamesSet = new Set(
    variablesDescriptionResult.map((desc) => desc.name),
  );

  const rows = riskVariables.filter(
    (rule) => rule.name.startsWith(retrieveNamesToFilter(0))
      && !rule.name.startsWith(retrieveNamesToFilter(1))
      && descriptionNamesSet.has(rule.name),
  );
  return (
    <>
      <LoadingButton
        loading={variablesDescriptionLoading || variablesLoading}
        size="small"
        onClick={setOpen}
        disabled={riskEvaluation === null}
      >
        <OpenInNewIcon />
      </LoadingButton>
      <FingoDialog
        title="Variables de Riesgo"
        subtitle={`Creacion: ${formatRiskEvaluationDateTime(riskVariables)}`}
        open={open}
        handleClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {riskVariables && variablesDescription && (
          <Stack direction="column" spacing={1}>
            <Stack direction="column">
              <RiskVariablesAlert variables={riskVariables} />
            </Stack>
            <Tabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(_, value) => {
                setSelectedTab(value);
              }}
            >
              <Tab label="Cliente" />
              <Tab label="Deudor" />
              <Tab label="Relación" />
            </Tabs>
            <Stack direction="row" spacing={1} justifyContent="center">
              <DicomComponent
                isNaturalPersonRut={client?.masterEntity.isNaturalPersonRut}
                masterEntityId={client?.id}
                label="Dicom Cliente"
              />
              <DicomComponent
                isNaturalPersonRut={receiver?.isNaturalPersonRut}
                masterEntityId={receiver?.id}
                label="Dicom Deudor"
              />
            </Stack>
            <FingoDataGrid
              rowCount={rows.length}
              rowHeight={50}
              rows={rows}
              columns={riskEvaluationsVariablesColumns(variablesDescription)}
              page={0}
              pageSize={rows.length}
            />
          </Stack>
        )}
      </FingoDialog>
    </>
  );
};

RiskVariables.propTypes = {
  riskEvaluation: PropTypes.shape({
    treeClientDebtorResult: PropTypes.bool.isRequired,
    treeClientResult: PropTypes.bool.isRequired,
    treeDebtorResult: PropTypes.bool.isRequired,
    rasClientDebtorResult: PropTypes.number.isRequired,
    rasClientResult: PropTypes.number.isRequired,
    rasDebtorResult: PropTypes.number.isRequired,
  }),
  documentId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  client: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    masterEntity: PropTypes.shape({
      isNaturalPersonRut: PropTypes.bool,
    }),
  }).isRequired,
  receiver: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    isNaturalPersonRut: PropTypes.bool,
  }).isRequired,
};

RiskVariables.defaultProps = {
  riskEvaluation: null,
};

export default RiskVariables;
