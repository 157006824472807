import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useBooleanState, useTextFieldInput } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { ADD_COMPANIES_TO_BLACKLIST, GET_COMPANY_EVALUATIONS, GET_COMPANY_ORDERING_EVALUATIONS } from '@fingo/lib/graphql';

const RiskEvaluationBlacklist = ({ companyName, companyId }) => {
  const [open, handleOpen] = useBooleanState();
  const [comments, setComments, resetComments] = useTextFieldInput('');
  const [addCompaniesToBlacklist, { loading }] = useMutation(ADD_COMPANIES_TO_BLACKLIST, {
    variables: {
      identifiers: [companyId],
      searchType: 'id',
      comments: [comments],
    },
    onCompleted: () => {
      handleOpen(false);
      resetComments();
    },
    refetchQueries: [
      GET_COMPANY_EVALUATIONS,
      GET_COMPANY_ORDERING_EVALUATIONS,
    ],
  });
  return (
    <>
      <Tooltip title="Agregar Empresa a Blacklist">
        <span>
          <IconButton
            onClick={handleOpen}
            sx={{
              maxWidth: '39px',
              maxHeight: '39px',
              borderRadius: 2,
              backgroundColor: 'red',
              color: 'white',
              '&.Mui-disabled': {
                color: null,
                backgroundColor: 'grey',
              },
              '&:hover': {
                backgroundColor: '#d32f2f',
                color: 'white',
              },
            }}
          >
            <RemoveCircle />
          </IconButton>
        </span>
      </Tooltip>
      <FingoDialog
        key="blacklist-dialog"
        id="blacklist-dialog"
        title="Agregar Empresa a Blacklist"
        open={open}
        handleClose={handleOpen}
        maxWidth="md"
        fullWidth
      >
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Typography>
              ¿Por qué quieres agregar a la empresa {companyName} a la blacklist?
            </Typography>
          </Grid>
          <Grid item sx={{ width: '70%' }}>
            <TextField
              variant="outlined"
              name="rejectionComments"
              label="Comentarios"
              value={comments}
              onChange={setComments}
              multiline
              fullWidth
              rows={4}
            />
          </Grid>
          <Grid item sx={{ marginTop: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={addCompaniesToBlacklist}
              loading={loading}
              sx={{ marginRight: 1 }}
            >
              Agregar a la Blacklist
            </LoadingButton>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                handleOpen(false);
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </FingoDialog>
    </>
  );
};

RiskEvaluationBlacklist.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default RiskEvaluationBlacklist;
